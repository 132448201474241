<template>
  <header class="z-50 relative" data-behaviour="TopNavigation">
    <div class="bg-white shadow-md py-5 xl:py-6">
      <nav class="container 2xl:max-w-screen-2xl mx-auto px-6">
        <div class="flex justify-between w-full">
          <div class="flex items-center xl:gap-x-10">
            <a :href="baseUrl" :title="t('logo_title', { portal: headerFooterLinks.location })" class="navbar-brand cursor-pointer">
              <UiIcon
                :src="headerFooterLinks.logo"
                width="125"
                height="30"
                alt="Travanto"
                :class="[
                  {'w-[180px] lg:w-[220px] h-[40px] lg:h-[58px] -my-[5px]' : longPortalName },
                  {'w-[125px] lg:w-[192px] h-[30px] lg:h-[48px]' : !longPortalName }
                ]"
              />
            </a>
            <a v-if="headerFooterLinks.location !== ''" href="/lastminute/" class="hidden xl:block font-semibold text-lg text-neutral-800 block cursor-pointer py-5 px-8 xl:py-5 xl:px-0">
              <UiLanguagePlaceholder domain="LayoutHeader" name="last_minute" />
            </a>
            <div v-if="headerFooterLinks.location !== '' && portalDe" class="relative hidden xl:block">
              <UiCollapsed :outside-click="true" absolute-position="top-6 left-0">
                <template #buttonActive>
                  <div class="text-petrol-500 font-bold text-lg block cursor-pointer flex items-center">
                    <UiLanguagePlaceholder domain="LayoutHeader" name="vacation" />
                    <UiIcon src="/svg/icons/select-arrow.svg" width="12" height="6" alt="Travanto" class="fill-petrol-500 rotate-180 inline-block ml-1" />
                  </div>
                </template>
                <template #buttonInactive>
                  <div :class="['text-neutral-800 font-semibold text-lg block cursor-pointer flex items-center']">
                    <UiLanguagePlaceholder domain="LayoutHeader" name="vacation" />
                    <UiIcon src="/svg/icons/select-arrow.svg" width="12" height="6" alt="Travanto" class="fill-neutral-800 inline-block ml-1" />
                  </div>
                </template>
                <template #collapseAbsolute>
                  <div class="mx-auto w-[620px] rounded-xl border border-neutral-50 bg-white mt-[29px] drop-shadow-[0_25px_25px_rgba(0,0,0,0.20)] relative">
                    <span class="arrow-up" />
                    <div class="grid grid-cols-12 gap-x-10 py-16 px-16 text-left">
                      <div v-if="headerFooterLinks.regiondiscovers?.length > 0" class="col-span-6">
                        <div class="text-2xl font-semibold mb-3">
                          <UiLanguagePlaceholder domain="LayoutHeader" name="discover" :variables="{ region : headerFooterLinks.location }" />
                        </div>
                        <div v-for="regiondiscover in headerFooterLinks.regiondiscovers" :key="regiondiscover.name" class="py-2 text-base">
                          <a v-if="!regiondiscover.maskedLink" :href="regiondiscover.url" :title="t(regiondiscover.name + '_title', {region: headerFooterLinks.location, preposition: headerFooterLinks.preposition, preposition2: headerFooterLinks.preposition2})" class="active:text-petrol-800 focus:text-petrol-800 hover:text-petrol-500 hover:underline hover:font-semibold">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="regiondiscover.name" :variables="{ region : headerFooterLinks.location, preposition: headerFooterLinks.preposition }" />
                          </a>
                          <UiMaskedLink v-if="regiondiscover.maskedLink" tag="button" :href="regiondiscover.url" :title="t(regiondiscover.name + '_title', {region: headerFooterLinks.location, preposition: headerFooterLinks.preposition, preposition2: headerFooterLinks.preposition2})" class="active:text-petrol-800 focus:text-petrol-800 hover:text-petrol-500 hover:underline hover:font-semibold">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="regiondiscover.name" :variables="{ region : headerFooterLinks.location }" />
                          </UiMaskedLink>
                        </div>
                      </div>
                      <div v-if="headerFooterLinks.regionbookings?.length > 0" class="col-span-6">
                        <div class="text-2xl font-semibold mb-3">
                          <UiLanguagePlaceholder domain="LayoutHeader" name="booking" :variables="{ region : headerFooterLinks.location }" />
                        </div>
                        <div v-for="regionbooking in headerFooterLinks.regionbookings" :key="regionbooking.name" class="py-2 text-base">
                          <a v-if="!regionbooking.maskedLink" :href="regionbooking.url" :title="t(regionbooking.name + '_title', {region: headerFooterLinks.location, preposition: headerFooterLinks.preposition})" class="active:text-petrol-800 focus:text-petrol-800 hover:text-petrol-500 hover:underline hover:font-semibold block">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="regionbooking.name" :variables="{ region : headerFooterLinks.location }" />
                          </a>
                          <UiMaskedLink v-if="regionbooking.maskedLink" tag="button" :href="regionbooking.url" :title="t(regionbooking.name + '_title', {region: headerFooterLinks.location, preposition: headerFooterLinks.preposition})" class="active:text-petrol-800 focus:text-petrol-800 hover:text-petrol-500 hover:underline hover:font-semibold">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="regionbooking.name" :variables="{ region : headerFooterLinks.location }" />
                          </UiMaskedLink>
                        </div>
                      </div>
                      <div v-if="headerFooterLinks.vacation_links.length > 0" class="col-span-6">
                        <div v-for="vacation_link in headerFooterLinks.vacation_links" :key="vacation_link.name" class="py-2 text-base">
                          <a :href="vacation_link.url" :title="t(vacation_link.name + '_title', {region: headerFooterLinks.location, preposition: headerFooterLinks.preposition})" class="active:text-petrol-800 focus:text-petrol-800 hover:text-petrol-500 hover:underline hover:font-semibold">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="vacation_link.name" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </UiCollapsed>
            </div>
          </div>
          <div class="hidden xl:flex flex-col xl:flex-row xl:gap-x-5 items-center items-stretch text-left xl:text-center">
            <!--<UiLanguageSelect v-show="viewdesktop.viewDesktop" class="z-40" />-->
            <div class="flex items-center">
              <UiMaskedLink
                href="/wishlist"
                :new-tab="false"
                tag="button"
                rel="external"
                class="hidden xl:block font-semibold text-lg text-neutral-800 inline-block cursor-pointer py-5 px-8 xl:py-3 xl:pl-5 xl:pr-0 -mb-[3px]"
              >
                <WishlistIconWithCounter />
              </UiMaskedLink>
            </div>
            <div class="flex items-center border-b border-neutral-200 xl:border-b-0">
              <a :href="headerFooterLinks.ferienwohnung_vermieten_url" target="_blank" rel="external" class="font-semibold text-lg text-neutral-800 block cursor-pointer py-5 px-8 xl:px-0">
                <UiLanguagePlaceholder domain="LayoutHeader" name="rent_out" />
              </a>
            </div>
            <div class="flex items-center py-5 px-8 xl:py-0 xl:px-0">
              <UiButton color="primary" :href="headerFooterLinks.gastgeber_url" target="_blank" rel="external">
                <UiLanguagePlaceholder domain="LayoutHeader" name="host" />
              </UiButton>
            </div>
          </div>
          <div class="xl:hidden flex gap-x-5 items-center">
            <UiMaskedLink
              href="/wishlist"
              :new-tab="false"
              tag="button"
              rel="external"
              class="xl:hidden font-semibold text-lg text-neutral-800 block cursor-pointer py-0 px-0 inline-block"
            >
              <WishlistIconWithCounter />
            </UiMaskedLink>
            <button class="xl:hidden cursor-pointer inline-block" :title="t('title_burgermenue')" @click="mobilnav.isOpen = !mobilnav.isOpen">
              <UiIcon src="/svg/icons/burger_menu.svg" width="26" height="26" class="fill-neutral-500 inline-block" />
            </button>
          </div>
        </div>
      </nav>
    </div>
    <UseFocusTrap v-if="mobilnav.isOpen" :options="{ immediate: true }">
      <nav v-if="mobilnav.isOpen" class="block xl:hidden fixed bg-white top-0 left-0 right-0 bottom-0 w-full h-full">
        <div class="flex flex-row-reverse border-b border-neutral-200 py-2">
          <button class="py-2 pl-0 pr-3 cursor-pointer" title="close" @click="mobilnav.isOpen = !mobilnav.isOpen">
            <UiIcon src="/svg/icons/cancel.svg" width="34" height="34" class="fill-neutral-800" />
          </button>
          <!--<UiLanguageSelect v-show="!viewdesktop.viewDesktop" />-->
        </div>
        <ul class="flex flex-col xl:flex-row text-left xl:text-center h-full overflow-auto pb-40">
          <li v-if="headerFooterLinks.location !== '' && portalDe" class="relative border-b xl:border-b-0 border-neutral-200">
            <UiCollapsed>
              <template #buttonActive>
                <div class="py-5 px-8 font-semibold text-xl text-petrol-500 flex cursor-pointer items-center">
                  <UiLanguagePlaceholder domain="LayoutHeader" name="vacation" />
                  <UiIcon src="/svg/icons/select-arrow.svg" width="12" height="6" alt="Travanto" class="fill-petrol-500 rotate-180 inline-block ml-1" />
                </div>
              </template>
              <template #buttonInactive>
                <div class="py-5 px-8 font-semibold text-xl text-neutral-800 flex cursor-pointer items-center">
                  <UiLanguagePlaceholder domain="LayoutHeader" name="vacation" />
                  <UiIcon src="/svg/icons/select-arrow.svg" width="12" height="6" alt="Travanto" class="fill-neutral-800 inline-block ml-1" />
                </div>
              </template>
              <template #collapse>
                <div v-if="headerFooterLinks.region_links !== false" class="flex flex-col gap-x-4 gap-y-10 pb-8 text-left px-8">
                  <div class="flex gap-x-3">
                    <UiIcon src="/svg/icons/entdecken.svg" width="25" height="25" alt="Entdecke mehr" class="fill-neutral-800 inline-block flex-none" />
                    <div class="flex flex-col items-start gap-y-5">
                      <div class="text-lg font-semibold mt-0.5">
                        <UiLanguagePlaceholder domain="LayoutHeader" name="discover" :variables="{ region : headerFooterLinks.location }" />
                      </div>
                      <div v-for="regiondiscover in headerFooterLinks.regiondiscovers" :key="regiondiscover.name" class="text-sm">
                        <a class="break-words" :href="regiondiscover.url">
                          <UiLanguagePlaceholder domain="LayoutHeader" :name="regiondiscover.name" :variables="{ region: headerFooterLinks.location, preposition: headerFooterLinks.preposition }" />
                        </a>
                      </div>
                      <template v-if="headerFooterLinks.vacation_links.length > 0">
                        <div v-for="vacation_link in headerFooterLinks.vacation_links" :key="vacation_link.name" class="text-sm">
                          <a :href="vacation_link.url" :title="t(vacation_link.name + '_title', { region: headerFooterLinks.location, preposition: headerFooterLinks.preposition })">
                            <UiLanguagePlaceholder domain="LayoutHeader" :name="vacation_link.name" />
                          </a>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="flex gap-x-3">
                    <UiIcon src="/svg/icons/buchen.svg" width="25" height="25" alt="Entdecke mehr" class="fill-neutral-800 inline-block flex-none" />
                    <div class="flex flex-col items-start gap-y-5">
                      <div class="text-lg font-semibold mt-0.5">
                        <UiLanguagePlaceholder domain="LayoutHeader" name="booking" :variables="{ region : headerFooterLinks.location }" />
                      </div>
                      <div v-for="regionbooking in headerFooterLinks.regionbookings" :key="regionbooking.name" class="col-span-1 text-sm">
                        <a class="break-words" :href="regionbooking.url">
                          <UiLanguagePlaceholder domain="LayoutHeader" :name="regionbooking.name" :variables="{ region : headerFooterLinks.location }" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </UiCollapsed>
          </li>
          <li class="border-b border-neutral-200 xl:border-b-0">
            <a href="/lastminute/" target="_blank" rel="external" class="font-semibold text-neutral-800 block cursor-pointer py-5 px-8 text-xl xl:py-3 xl:px-5 xl:text-lg">
              <UiLanguagePlaceholder domain="LayoutHeader" name="last_minute" />
            </a>
          </li>
          <li class="border-b border-neutral-200 xl:border-b-0 text-xl xl:text-base">
            <a :href="headerFooterLinks.ferienwohnung_vermieten_url" target="_blank" rel="external" class="font-semibold text-neutral-800 block cursor-pointer py-5 px-8 text-xl xl:py-3 xl:px-5 xl:text-lg">
              <UiLanguagePlaceholder domain="LayoutHeader" name="rent_out" />
            </a>
          </li>
          <li class="py-5 px-8 xl:py-0 xl:px-0">
            <UiButton color="primary" :href="headerFooterLinks.gastgeber_url" target="_blank" rel="external">
              <UiLanguagePlaceholder domain="LayoutHeader" name="host" />
            </UiButton>
          </li>
        </ul>
      </nav>
    </UseFocusTrap>
  </header>
</template>

<script setup lang="ts">
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
const t = useTranslations('LayoutHeader').translate

const { links: headerFooterLinks, portalsWithLongLogos } = usePortalData()

const longPortalName = ref(false)

const mobilnav = reactive({
  isOpen: false
})
const { currentPortal } = usePortals()
const portalDe = currentPortal.value.portal.lang === 'de'
const baseUrl = currentPortal.value.portal.home.replace('http://', 'https://')

if (portalsWithLongLogos.includes(headerFooterLinks.name)) {
  longPortalName.value = true
}
</script>

<style lang="scss" scoped>
.arrow-up:before {
  @apply rotate-[225deg] top-[-15px] absolute left-[70px] border-solid border-[15px] border-t-transparent border-l-transparent border-b-white border-r-white shadow-[3px_3px_6px_rgba(0,0,0,0.05)] ;
  content:"";
}
</style>
